import { Theme } from '@aws-amplify/ui-react';

export const theme: Theme  = {
    name: 'budgetBunnyTheme',
    tokens: {
        colors: {
            font: {
                primary: { value: '#667ABE' },
                interactive: { value: '#667ABE '},
            },
        },
        components: {
            button: {
                primary: {
                    backgroundColor: { value: '#667ABE '},
                    _hover: {
                        backgroundColor: { value: '#67A8BF'}
                    }
                },
                link: {
                    _hover: {
                        backgroundColor: { value: '#67A8BF'}
                    }
                }
            }
        }
    },
};