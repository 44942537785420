import { Tabs, Tab, Typography, Container, Grid, Alert, AlertTitle } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import './App.css';
import { Dashboard } from './Components/Views/Dashboard/Dashboard';
import { generateMockTransactions, Transaction } from './Components/Views/Transactions/DataUtilities';
import { Transactions } from './Components/Views/Transactions/Transactions';
import { User } from './Model/User/User';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { theme } from './Themes/budgetBunnyTheme'
import { BrowserRouter, Routes, Route } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



Amplify.configure(awsExports);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{padding: '2em'}}>
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
            This app is undergoing rapid development. 
            It is not yet open to the public. 
            A public preview will be available in the near future.
            Feel free to check back for updates.
        </Alert>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid item container alignItems='center' justifyContent='center'>
            <img src="BudgetBunnyLogo.png" alt="Budget Bunny Logo" height={'300em'}/>
          </Grid>
          <Grid item container alignItems='center' justifyContent='center'>
            <Authenticator>
              {({ signOut, user }) => (
                <main>
                  <h1> { user ? `Hello ${user.username}` : '' }</h1>
                  <button onClick={signOut}>Sign out</button>
                </main>
              )}
            </Authenticator>
          </Grid>
          <Grid item sx={{ 
            backgroundColor: 'white', 
            margin: '2em', 
            padding: '1em',
            borderColor: theme.tokens?.colors?.font?.primary?.value,
            borderWidth: '1px',
            borderStyle: 'solid'
          }}>
            <Grid item>
              <h4>About</h4>
              <p>Budget Bunny is an intuitive expense management and budgeting app designed for couples, roommates, families, 
                and anyone who regularly splits expenses. It will offer users unparalleled insight into their spending habits and 
                enable them to take control of their financial future.
              </p>
              <h4>Features</h4>
              <p>
                <ul>
                  <li>Sync your bank transactions via Plaid and crypto transactions from Polygon, Avalanche, and Ethereum</li>
                  <li>Categorize each transaction via one or more custom tags and categories</li>
                  <li>"Budget Burnup Chart": Stay on track to meet your monthly spending goals by comparing 
                    actual expenses and forecasted expenses at each day of the month</li>
                  <li>Keep track of how much you owe your [spouse/fiance/significant other/roommate/friend]...and how much they owe you</li>
                  <li>Create preliminary expense reports using custom rules (i.e. 50% of all restaurant transactions made after 5 pm are dates and should be split 50/50). Fine tune the reports manually later.</li>
                  <li>Add hypothetical and expected future expenses into your budget (2023 ski trip?)</li>
                </ul>
              </p>
              <h4>Why another budgeting app?</h4>
              <p>
                Great question! My fiance and I often alternate paying for <i>things</i> (utilities, airplane tickets, dinner dates).
                We try to alternate "who pays" such that our debts to each other cancel out.
                However, this hardly works out in practice. 
              </p>
              <p>
                Furthermore, let's say my fiance puts a series of restaurant transactions for the two of us
                on her card, and I put a series of recreational transactions for the two of us on my card.
                It will skew her cumulative restaurant spending for the month higher, and my cumulative recreational spending
                for the month higher.
              </p>
              <p>
                In the age of student loans (sigh), it is critically imporant to stick to budget targets and the first step to achieving that goal
                is having an accurate snapshot of your spending at any given time. I have used numerous budgeting and expense management apps such
                as Quicken, Mint, and Honeydue. Sadly, none of them provide my fiance and I with the tools needed to accurately track our current and
                future expenses. Up until now, we've relied on a combination of these apps and Excel workbooks to fulfil our budgeting needs. But no longer!
              </p>
              <p>
                As a scrum master, data scientist, and software engineer, I use data all the time to inform my decisions in the workplace.
                Budget Bunny will allow me -- and so many others -- to do the same with regards to their personal finances.
              </p>
              <p><i>Designed and developed by John Fantell</i></p>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

function MainView() {
  const [value, setValue] = React.useState(0);
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [users, setUsers] = React.useState<User[]>([]);
  
  const addTransactionHandler = (newTxs: Transaction[]) => {
    setTransactions((existingTxs) => existingTxs.concat(newTxs));
  }

  React.useEffect(() => {
    // Fetch transactions from Database
    // Fetch users from Database

    // Update React State to include transactions and users
    setUsers([
        {firstName: 'John', lastName: 'Fantell'},
        {firstName: 'Hayley', lastName: 'Roy Gill'}
    ]);
    addTransactionHandler(generateMockTransactions())
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <h1>
        BudgetBunny
      </h1>
      <p>
        Expense tracking and budget management for couples. This is a personal / hobby project designed by John Fantell.
      </p>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Dashboard" {...a11yProps(0)} />
            <Tab label="Transactions" {...a11yProps(1)} />
            <Tab label="Settings" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Dashboard 
            transactions={transactions} users={[]}        />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Transactions 
            transactions={transactions}
            users={Object.values(users)}
            addTransactionHandler={addTransactionHandler}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          Coming Soon...
        </TabPanel>
      </Box>
    </>  
  );
}

export default App;
