import { Button } from "@mui/material";
import React from "react";
import { User } from "../../../Model/User/User";
import { createEmptyTransaction, generateMockTransactions, Transaction } from "./DataUtilities";
import EnhancedTable from "./TableUtilities";

export const Transactions = (props: {
    transactions: Transaction[], 
    users: User[], 
    addTransactionHandler: (transactions: Transaction[]) => void 
}) : JSX.Element => {
    const { transactions, addTransactionHandler } = props;
    const addNewTransactionHandler = () => {
        console.log('Adding a new transaction manually');
        const emptyTransaction = createEmptyTransaction();
        addTransactionHandler([emptyTransaction]);
    }

    return (
        <>
            <Button 
                variant="contained"
                onClick={() => addNewTransactionHandler()}>Add New Transaction</Button>
            <EnhancedTable
                transactions={transactions}
            />
        </>
    );
}